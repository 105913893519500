import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import './GSCard.scss'

interface Props {
  title: string
  content: string
  img?: FluidObject
}

const GSCard: React.FC<Props> = ({ title, content, img }) => {
  return (
    <div className="GSCard">
      <div className="img">
        {img && (
          <GatsbyImage
            image={img}
            loading="eager"
            alt={title}
            imgStyle={{ objectFit: 'contain' }}
            className="card-image"
          />
        )}
      </div>
      <div className="text">
        <h2>{title}</h2>
        <p>{content}</p>
      </div>
    </div>
  )
}

export default GSCard

import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

import './FAQ.scss'
import { Col, Container, Row } from 'react-bootstrap'
import { formatForBigQuery, isMobile } from '../../formatForBigQuery'

interface Props {
  question: string
  answer?: string[]
  answerElement?: React.ReactNode
  color?: string
  answerBg?: string
  questinNumber: number
  expanded: boolean
  setExpanded(n: number): void
}

const FAQ: React.FC<Props> = ({
  question,
  answer,
  answerElement,
  color = '#000',
  answerBg,
  questinNumber,
  expanded,
  setExpanded,
}) => {
  const data = useStaticQuery(graphql`
    {
      plus: file(relativePath: { eq: "plus-blue.png" }) {
        childImageSharp {
          gatsbyImageData(width: 10, layout: CONSTRAINED)
        }
      }
      minus: file(relativePath: { eq: "minus-blue.png" }) {
        childImageSharp {
          gatsbyImageData(width: 10, layout: CONSTRAINED)
        }
      }
    }
  `)

  let answerStyles: React.CSSProperties = {}
  if (answerBg) {
    answerStyles = {
      background: answerBg,
      padding: '10px 10px 1px',
      borderRadius: '8px',
    }
  }

  return (
    <div className="faq">
      <button
        type="button"
        className="q"
        onClick={() => {
          setExpanded(questinNumber)
        }}
      >
        <Container>
          <Row className="q-row">
            <Col lg={9} className="q-col">
              <span style={{ color }}>{question}</span>
              <GatsbyImage
                image={
                  data[expanded ? 'minus' : 'plus'].childImageSharp
                    .gatsbyImageData
                }
                alt="expand"
                className="plus"
                imgStyle={{ objectFit: 'contain' }}
              />
            </Col>
          </Row>
        </Container>
      </button>
      {expanded ? (
        <div className="a" style={answerStyles}>
          <Container>
            <Row className="q-row">
              <Col lg={9} className="q-col">
                {!answerElement &&
                  answer?.map(a => {
                    return <p key={a}>{a}</p>
                  })}
                {answerElement ? <>{answerElement}</> : null}
              </Col>
            </Row>
          </Container>
        </div>
      ) : null}
    </div>
  )
}

export default FAQ

import React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import GettingStartedContent from '../../components/HelpCenter/GettingStarted/GettingStarted'
import { Faq } from '../../components/FAQs/faqs-list-interface'
import { Breadcrumb } from '../../components/Breadcrumbs/Breadcrumbs'
import {
  HC_PROMOTIONS_OFFERS,
  HELP_CENTRE,
} from '../../components/internal-links'
import {
  generateBreadcrumbsSchema,
  generateFaqSchema,
  generateOrgSchema,
  generateReferralVideoSchema,
  generateWebsiteSchema,
} from '../../lib/generate-schema'
import {
  pokerOneLinksStaticPage,
  rummyOneLinksStaticPage,
} from '../../components/one-links'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'Help Centre',
    url: HELP_CENTRE,
  },
  {
    title: 'Promotions and Offers',
    url: HC_PROMOTIONS_OFFERS,
  },
]

const PromotionsAndOffers: React.FC = () => {
  const [promotionsAndOffersFaqsSt, setPromotionsAndOffersFaqsSt] = useState<
    Faq[]
  >([])

  useEffect(() => {
    import('../../components/FAQs/faqs-list').then(
      ({ promotionsAndOffersFaqs }) =>
        setPromotionsAndOffersFaqsSt(promotionsAndOffersFaqs)
    )
  }, [])

  return (
    <Layout
      showBreadcrumbs
      breadcrumbs={breadcrumbs}
      pokerOneLink={pokerOneLinksStaticPage}
      rummyOneLink={rummyOneLinksStaticPage}
    >
      <Helmet>
        {process.env.GATSBY_STAGING === 'true' && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>
      <SEO
        title="Promotions & Offers: Get Help on Promotions & Offers on Mega App"
        description="Visit our promotions and offers help centre for answers to queries on how to get cashback offers, how to refer and earn, among others. Click to read more!"
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
        faqSchema={generateFaqSchema(promotionsAndOffersFaqsSt)}
        videoSchema={[generateReferralVideoSchema()]}
      />
      <GettingStartedContent pageIndex={5} faqs={promotionsAndOffersFaqsSt} />
    </Layout>
  )
}

export default PromotionsAndOffers

import { graphql, useStaticQuery } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import * as links from '../internal-links'

export interface HelpCenterCard {
  title: string
  content: string
  img: IGatsbyImageData
  link: string
}

const useCardsList = (): HelpCenterCard[] => {
  const data = useStaticQuery(graphql`
    {
      gs: file(relativePath: { eq: "getting-started.png" }) {
        childImageSharp {
          gatsbyImageData(width: 77, layout: CONSTRAINED)
        }
      }
      pw: file(relativePath: { eq: "payments-withdrawals.png" }) {
        childImageSharp {
          gatsbyImageData(width: 77, layout: CONSTRAINED)
        }
      }
      kyc: file(relativePath: { eq: "kyc.png" }) {
        childImageSharp {
          gatsbyImageData(width: 77, layout: CONSTRAINED)
        }
      }
      cg: file(relativePath: { eq: "contests-gameplay.png" }) {
        childImageSharp {
          gatsbyImageData(width: 77, layout: CONSTRAINED)
        }
      }
      gr: file(relativePath: { eq: "game-rules.png" }) {
        childImageSharp {
          gatsbyImageData(width: 77, layout: CONSTRAINED)
        }
      }
      po: file(relativePath: { eq: "promotions-offers.png" }) {
        childImageSharp {
          gatsbyImageData(width: 77, layout: CONSTRAINED)
        }
      }
    }
  `)

  const cardsList: HelpCenterCard[] = [
    {
      title: 'Getting Started',
      content: 'Learn how to install the app & sign up on Mega',
      img: data.gs.childImageSharp.gatsbyImageData,
      link: links.HC_GETTING_STARTED,
    },
    {
      title: 'Payments and Withdrawals',
      content: 'Learn about deposits and withdrawals',
      img: data.pw.childImageSharp.gatsbyImageData,
      link: links.HC_PAYMENTS_AND_WITHDRAWALS,
    },
    {
      title: 'KYC',
      content: 'All you need to know about KYC (Know Your Customer)',
      img: data.kyc.childImageSharp.gatsbyImageData,
      link: links.HC_KYC,
    },
    {
      title: 'Contests and Gameplay',
      content: 'Know more about our games & contests',
      img: data.cg.childImageSharp.gatsbyImageData,
      link: links.HC_CONTESTS_GAMEPLAYS,
    },
    {
      title: 'Game Rules',
      content: 'Learn the rules of the game on Mega',
      img: data.gr.childImageSharp.gatsbyImageData,
      link: links.HC_GAME_RULES,
    },
    {
      title: 'Promotions and Offers',
      content: 'Learn about exciting offers on the app',
      img: data.po.childImageSharp.gatsbyImageData,
      link: links.HC_PROMOTIONS_OFFERS,
    },
  ]

  return cardsList
}

export default useCardsList

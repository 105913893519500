import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import './GettingStarted.scss'
import { Col, Container, Row } from 'react-bootstrap'
import GSCard from './GSCard'
import defaultFaqs, { Faq } from '../../FAQs/faqs-list'
import FAQ from '../../FAQs/FAQ2'
import useCardsList from '../useHelpCenterCardsList'
import useFaqExpansion from '../../../hooks/useFaqExpansion'
import { CONTACT_US } from '../../internal-links'

interface Props {
  pageIndex?: number
  faqs?: Faq[]
}

const GettingStarted: React.FC<Props> = ({ pageIndex = 0, faqs }) => {
  const { expandedFaq, setExpandedFaq } = useFaqExpansion()
  const cardsList = useCardsList()
  const faqsList = faqs || defaultFaqs

  return (
    <div id="GettingStarted">
      <div className="header">
        <Container>
          <GatsbyImage
            image={cardsList[pageIndex].img}
            loading="eager"
            alt={cardsList[pageIndex].title}
            imgStyle={{ objectFit: 'contain' }}
            className="rocket"
          />
          <h1>{cardsList[pageIndex].title}</h1>
          <p>{cardsList[pageIndex].content}</p>
        </Container>
      </div>

      <div className="gs-faqs">
        {faqsList.map((f, i) => {
          return (
            <React.Fragment key={f.question}>
              <FAQ
                question={f.question}
                answer={f.answer}
                answerElement={f.answerElement}
                expanded={expandedFaq === i}
                setExpanded={setExpandedFaq}
                questinNumber={i}
              />
              {i !== faqsList.length - 1 ? (
                <Container>
                  <Row className="s-row">
                    <Col lg={9}>
                      <div className="separator" />
                    </Col>
                  </Row>
                </Container>
              ) : null}
            </React.Fragment>
          )
        })}
      </div>

      <div className="gs-info-cards">
        <Container>
          <Row xs={1} md={2} lg={3}>
            <Col className="d-none d-lg-block gs-col">
              <div className="gs-col-inner">
                <h2 className="GSCard other">
                  EXPLORE <br />
                  OTHER <br />
                  <span className="underline">CATEGORIES</span>
                </h2>
              </div>
            </Col>
            {cardsList
              .filter((_, i) => i !== pageIndex)
              .map(c => {
                return (
                  <Col key={c.title} className="gs-col">
                    <Link to={c.link}>
                      <GSCard title={c.title} content={c.content} img={c.img} />
                    </Link>
                  </Col>
                )
              })}
          </Row>
        </Container>
      </div>

      <div className="gs-help-center">
        <Container>
          <h2>Couldn&apos;t find what you were looking for ?</h2>
          <Link to={CONTACT_US}>CONTACT US</Link>
        </Container>
      </div>
    </div>
  )
}

export default GettingStarted
